import React from "react";
import { Provider } from "react-redux";

const OfferInput = ({
  name,
  placeholder,
  buttonShow,
  buttonText,
  type,
  setSmsOptIn,
  stateToSet,
  sendType,
  regex,
  title,
  value,
  setUserPassword,
  loading,
  handleAmplitudeClaimBox,
}) => {
  const [val, setVal] = React.useState(!!value ? value : "");

  React.useEffect(() => {
    const inputs = document.getElementsByClassName("offer-input");
    const thisInput =
      inputs[0]?.value?.length > 0 && inputs?.length > 0
        ? inputs[1]
        : inputs[0];
    thisInput?.select();
  }, []);

  function formatPhoneNumber(value) {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength === 11) {
      return `+${phoneNumber.slice(0, 1)} ${phoneNumber.slice(
        1,
        4,
      )} ${phoneNumber.slice(4, 7)}-${phoneNumber.slice(7, 11)}`;
    }
  }

  function handleField(e) {
    const formattedInputValue =
      sendType === "phone"
        ? formatPhoneNumber(
            e.target.value[0] != 1 && e.target.value[0] != "+"
              ? "1" + e.target.value
              : e.target.value,
          )
        : e.target.value;
    setVal(formattedInputValue);
    !!stateToSet ? stateToSet(formattedInputValue) : null;
    if (type === "phone" && formattedInputValue?.length === 15) {
      e.target.blur();
      handleAmplitudeClaimBox(
        "enter_phone_number_for_offer",
        formattedInputValue,
      );
      if (window.orientation > 1) {
        document.getElementById("phone-claim-box").scrollIntoView();
      }
    }
    if (type === "checkbox" && name === "sms_opt_in") {
      setSmsOptIn(e.target.checked);
      if (e.target.checked) handleAmplitudeClaimBox();
    }
    if (type === "password") {
      setUserPassword(formattedInputValue);
    }
  }

  let autoComplete;

  if (type === "phone") {
    autoComplete = "tel";
  } else if (type === "email") {
    autoComplete = "email";
  }

  const getClassName = (type, placeholder, name) => {
    if (type === "phone") {
      return "input-container";
    } else if (type === "email" && placeholder === "Your Email") {
      return "input-container";
    } else if (type === "subscriber_email") {
      return "input-container";
    } else if (type === "email") {
      return "email-input-container";
    } else if (type === "password") {
      return "password-input-container";
    } else if (type === "checkbox" && name === "sms_opt_in") {
      return "sms-container";
    } else {
      return "alt-input-container";
    }
  };

  return (
    <div>
      <div className={getClassName(type, placeholder, name)}>
        <input
          placeholder={placeholder}
          type={type === "phone" ? "tel" : type}
          name={name}
          value={val}
          onChange={(e) => handleField(e)}
          pattern={regex}
          title={title}
          autoComplete={autoComplete}
          className={
            type === "checkbox" && name === "sms_opt_in"
              ? "sms-checkbox"
              : placeholder === "Email"
                ? "signin-input"
                : "offer-input"
          }
          id={type === "checkbox" && name === "sms_opt_in" ? "sms_opt_in" : ""}
          required
        />
      </div>
      {type !== "checkbox" &&
        type !== "email" &&
        type !== "phone" &&
        type !== "subscriber_email" && (
          <div className="org-tos">
            By continuing you agree to our{" "}
            <a href="https://inkind.com/terms" target="_blank">
              Terms of Service
            </a>{" "}
            and{" "}
            <a href="https://inkind.com/privacy" target="_blank">
              Privacy Policy
            </a>
            .
          </div>
        )}
      {type !== "email" && type !== "phone" ? (
        <button
          className={
            buttonText === "Next" && val.length !== 0
              ? "active-next-button"
              : buttonText === "Claim" && val.length !== 0
                ? "active-claim-button"
                : buttonText === "Verify" && val.length !== 0
                  ? "active-verify-button"
                  : buttonText === "Sign In" && val.length !== 0
                    ? "active-signin-button"
                    : "inactive-button"
          }
          type="submit"
          disabled={val.length === 0 || loading}
          style={{ display: !!buttonShow ? "" : "none" }}
        >
          {loading ? (
            <div
              className="loading-container"
              style={{ display: loading === true ? "" : "none" }}
            >
              <div className="loading-screen">
                <div className="loading-ic"></div>
              </div>
            </div>
          ) : (
            buttonText
          )}
        </button>
      ) : null}
    </div>
  );
};

export default OfferInput;
