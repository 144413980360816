import React from "react";
import OfferInput from "./OfferInput";
import { createEmailRegex } from "@lib/helpers";

const ClaimBox = ({
  value,
  expiration,
  email,
  phoneNum,
  restrictNew,
  sendType,
  setSmsOptIn,
  handleContactSubmit,
  stateToSet,
  limitedHours,
  redeemableShortDays,
  inkindStateRequiredEmails,
  ikname,
  inkindStatePrivacyUrl,
  inkindStateTermsUrl,
  inkindStateIncentiveNoticeUrl,
  inkindStateRequiredEmailsDisplay,
  loading,
  handleAmplitude,
  offerType,
  offerAmplitudePresenter,
  ocpSlidesExperiment,
  slug,
  requireEmailOptIn,
  brandName,
}) => {
  let emailRegex = createEmailRegex(inkindStateRequiredEmails);
  const offerClaimDetails = () => {
    const dateParts =
      expiration?.mode === "set_date" ? expiration.date.split("-") : [];
    let offerClaimTextDetails = [
      "Offer expires tonight after claiming.",
      `Offer is valid for ${expiration.days} day${
        expiration.days === 1 ? "" : "s"
      } after claiming.`,
      `Offer is valid until ${new Date(
        dateParts[0],
        Number(dateParts[1]) - 1,
        dateParts[2],
      ).toLocaleDateString("en-US")}.`,
      `Offer is valid until ${new Date(
        dateParts[0],
        Number(dateParts[1]) - 1,
        dateParts[2],
      ).toLocaleDateString(
        "en-US",
      )} and can only be used any ${redeemableShortDays} that the venue is open.`,
      `Offer is valid for ${expiration.days} day${
        expiration.days === 1 ? "" : "s"
      } after claiming and can only be used any ${redeemableShortDays} that the venue is open.`,
    ];
    if (!limitedHours && expiration?.mode === "days" && expiration.days <= 0) {
      return offerClaimTextDetails[0];
    } else if (
      !limitedHours &&
      expiration?.mode === "days" &&
      expiration.days > 0
    ) {
      return offerClaimTextDetails[1];
    } else if (
      limitedHours &&
      expiration?.mode === "days" &&
      expiration.days > 0
    ) {
      return offerClaimTextDetails[4];
    } else if (limitedHours && expiration?.mode === "set_date") {
      return offerClaimTextDetails[3];
    } else if (!limitedHours && expiration?.mode === "set_date") {
      return offerClaimTextDetails[2];
    } else if (limitedHours && expiration?.mode === "no_expiration") {
      return `This offer can be used anytime ${redeemableShortDays} that the venue is open.`;
    } else {
      return "";
    }
  };
  const handleAmplitudeClaimBox = (eventName, phoneNum) => {
    handleAmplitude(eventName, {
      phone_number: phoneNum,
    });
  };

  const claimButtonText =
    offerType === "ambassador_referral"
      ? `Claim your ${value}`
      : offerAmplitudePresenter.offer_claim_button_text;

  const claimButtonColor = offerAmplitudePresenter?.offer_claim_button_color
    ? {
        style: {
          backgroundColor: offerAmplitudePresenter?.offer_claim_button_color,
        },
      }
    : {};

  return (
    <>
      {requireEmailOptIn ? (
        <div className="claim-container" style={{ paddingTop: "10px" }}>
          <form
            className="email-container input-form"
            onSubmit={(e) => handleContactSubmit(e)}
          >
            <div className="offer-input-container">
              <div className="offer-content">
                <div className="offer-claim-header">
                  <p className="phone-info">Enter your email</p>
                </div>
                <OfferInput
                  name={"Email"}
                  placeholder={"Email "}
                  buttonShow={false}
                  type={"subscriber_email"}
                  stateToSet={stateToSet}
                  sendType={sendType}
                  regex={emailRegex}
                  title={inkindStateRequiredEmailsDisplay}
                />
                <div className="offer-claim-header phone-number-field">
                  <p className="phone-info">Enter your phone number</p>
                </div>
                <OfferInput
                  name={"Phone"}
                  placeholder={"(555) 555-5550"}
                  buttonText={"Claim"}
                  type={"phone"}
                  stateToSet={stateToSet}
                  sendType={"phone"}
                  regex={"^.{15,15}$"}
                  title={"Please enter a valid phone number."}
                  handleAmplitudeClaimBox={handleAmplitudeClaimBox}
                />
                <div className="tos-container">
                  <OfferInput
                    className="sms-checkbox"
                    name={"sms_opt_in"}
                    type={"checkbox"}
                    setSmsOptIn={setSmsOptIn}
                    handleAmplitudeClaimBox={handleAmplitudeClaimBox.bind(
                      null,
                      "check_marketing_agreement",
                      phoneNum,
                    )}
                  />
                  <div className="claim-tos-container">
                    <label className="sms-text" htmlFor="sms_opt_in">
                      I agree to receive text messages from inKind, including
                      verification & marketing messages at the phone number
                      provided. Reply STOP to cancel. Message & data rates may
                      apply.
                    </label>
                    <div className="tos">
                      {offerClaimDetails()}
                      {requireEmailOptIn ? (
                        <div>
                          {"This offer can be used anytime"}.
                          {restrictNew ? "Valid for new members only." : ""} By
                          continuing you agree to receive marketing emails from
                          &nbsp;
                          {brandName} in partnership with inKind and consent to
                          the inKind
                          <a
                            className="tos-link text-spacing"
                            href={inkindStateTermsUrl}
                            target="_blank"
                          >
                            Terms of Service
                          </a>
                          ,
                          <a
                            className="tos-link text-spacing"
                            href={inkindStatePrivacyUrl}
                            target="_blank"
                          >
                            Privacy Policy
                          </a>
                          , and
                          <a
                            className="tos-link text-spacing"
                            href={inkindStateIncentiveNoticeUrl}
                            target="_blank"
                          >
                            Notice of Financial Incentive
                          </a>
                          . Offers may not be combined with any other offer.
                        </div>
                      ) : (
                        <>
                          {" This offer can be used anytime. "}
                          {restrictNew ? "Valid for new members only." : ""} By
                          continuing you agree to our{" "}
                          <a
                            className="tos-link text-spacing"
                            href={inkindStateTermsUrl}
                            target="_blank"
                          >
                            Terms of Use
                          </a>
                          and
                          <a
                            className="tos-link text-spacing"
                            href={inkindStateIncentiveNoticeUrl}
                            target="_blank"
                          >
                            Privacy Policy
                          </a>
                          . Offers may not be combined with any other offer.
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <button
                  id="phone-claim-box"
                  className="offer-claim-button"
                  {...claimButtonColor}
                  disabled={!phoneNum || loading}
                  type="submit"
                >
                  {loading ? (
                    <div
                      className="loading-container"
                      style={{ display: loading === true ? "" : "none" }}
                    >
                      <div className="loading-screen">
                        <div className="loading-ic"></div>
                      </div>
                    </div>
                  ) : (
                    `${claimButtonText}`
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div
          className={
            sendType === "email" ? "email-claim-container" : "claim-container"
          }
        >
          <div className="offer-claim-header">
            <p className="phone-info">
              {sendType === "email"
                ? "Enter your email"
                : "Enter your phone number"}
            </p>
          </div>
          <form
            className="email-container input-form"
            onSubmit={(e) => handleContactSubmit(e)}
          >
            {sendType === "email" ? (
              <div className="offer-input-container">
                <OfferInput
                  name={"Email"}
                  placeholder={"Your Email"}
                  buttonShow={true}
                  buttonText={"Claim"}
                  type={"email"}
                  stateToSet={stateToSet}
                  sendType={sendType}
                  regex={emailRegex}
                  title={inkindStateRequiredEmailsDisplay}
                />
                <div>
                  <div className="email-tos">
                    {offerClaimDetails()}{" "}
                    {restrictNew ? "Valid for new members only." : ""} By
                    continuing you agree to our{" "}
                    <a
                      className="tos-link"
                      href={inkindStateTermsUrl}
                      target="_blank"
                    >
                      Terms of Use
                    </a>{" "}
                    and{" "}
                    <a
                      className="tos-link"
                      href={inkindStatePrivacyUrl}
                      target="_blank"
                    >
                      Privacy Policy
                    </a>
                    . Offers may not be combined with any other offer.
                  </div>
                </div>

                <button
                  className={email ? "offer-caim-button" : "inactive-button"}
                  disabled={!email || loading}
                  type="submit"
                >
                  {loading ? (
                    <div
                      className="loading-container"
                      style={{ display: loading === true ? "" : "none" }}
                    >
                      <div className="loading-screen">
                        <div className="loading-ic"></div>
                      </div>
                    </div>
                  ) : (
                    "Claim"
                  )}
                </button>
              </div>
            ) : (
              <div className="offer-input-container">
                <div className="offer-content">
                  <OfferInput
                    name={"Phone"}
                    placeholder={"Your Phone"}
                    buttonText={"Claim"}
                    type={"phone"}
                    stateToSet={stateToSet}
                    sendType={sendType}
                    regex={"^.{15,15}$"}
                    title={"Please enter a valid phone number."}
                    handleAmplitudeClaimBox={handleAmplitudeClaimBox}
                  />
                  <div className="tos-container">
                    <OfferInput
                      className="sms-checkbox"
                      name={"sms_opt_in"}
                      type={"checkbox"}
                      setSmsOptIn={setSmsOptIn}
                      handleAmplitudeClaimBox={handleAmplitudeClaimBox.bind(
                        null,
                        "check_marketing_agreement",
                        phoneNum,
                      )}
                    />
                    <div className="claim-tos-container">
                      <label className="sms-text" htmlFor="sms_opt_in">
                        I agree to receive text messages from {ikname},
                        including verification &amp; marketing messages at the
                        phone number provided. Reply STOP to cancel. Message
                        &amp; data rates may apply.
                      </label>
                      <div className="tos">
                        {offerClaimDetails()}
                        {" This offer can be used anytime. "}
                        {restrictNew ? "Valid for new members only." : ""} By
                        continuing you agree to our{" "}
                        <a
                          className="tos-link"
                          href={inkindStateTermsUrl}
                          target="_blank"
                        >
                          Terms of Use
                        </a>{" "}
                        and{" "}
                        <a
                          className="tos-link"
                          href={inkindStatePrivacyUrl}
                          target="_blank"
                        >
                          Privacy Policy
                        </a>
                        . Offers may not be combined with any other offer.
                      </div>
                    </div>
                  </div>
                  <button
                    id={sendType === "phone" ? "phone-claim-box" : null}
                    className={
                      sendType === "phone" && phoneNum
                        ? "offer-claim-button"
                        : "inactive-button"
                    }
                    {...claimButtonColor}
                    disabled={!phoneNum || loading}
                    type="submit"
                  >
                    {loading ? (
                      <div
                        className="loading-container"
                        style={{ display: loading === true ? "" : "none" }}
                      >
                        <div className="loading-screen">
                          <div className="loading-ic"></div>
                        </div>
                      </div>
                    ) : (
                      `${claimButtonText}`
                    )}
                  </button>
                </div>
              </div>
            )}
          </form>
        </div>
      )}
    </>
  );
};

export default ClaimBox;
